import {Accept, Awsome, Knowledgeable, NotStarted, Preparation} from "../assets/svg";
import {Gender} from "../components/Constants";
import {DefaultProfileKo, Female, Male, NoneGender} from "../assets/img";

export const colorForStatus = (status) => {
    switch (status) {
        case 1:
            return 'bg-active';
        case 2:
            return 'bg-attended';
        case 3:
            return 'bg-missed';
        case 4:
            return 'bg-postponed';
        case 5:
            return 'bg-frozen';
        case 6:
            return 'bg-holiday';
        case 7:
            return 'bg-weekend';
        case 8:
            return 'bg-no-lesson';
        case 9:
            return 'bg-system-postponed';
        case 10:
            return 'bg-deleted';
        default:
            return "ff0000";
    }
};

//bookTitle'larda çevirileri yazdırmak için kullanılan method
export const calendarTranslationByTitle = (bookTitle, language) => {
    switch (bookTitle) {
        case "Dersi kaçırdı":
            return language === "tr" ? "Dersi Kaçırdı" : language === "en" ? "Missed" : bookTitle;
        case "Erteledi":
            return language === "tr" ? "Erteledi" : language === "en" ? "Postponed" : bookTitle;
        case "Dondurdu":
            return language === "tr" ? "Dondurdu" : language === "en" ? "Frozen" : bookTitle;
        case "Tatil":
            return language === "tr" ? "Tatil" : language === "en" ? "Holiday" : bookTitle;
        case "Hafta sonu":
            return language === "tr" ? "Hafta Sonu" : language === "en" ? "Weekend" : bookTitle;
        case "Ders yok":
            return language === "tr" ? "Ders Yok" : language === "en" ? "No Lesson" : bookTitle;
        case "Sistem erteleme":
            return language === "tr" ? "Sistem Erteleme" : language === "en" ? "System Postponed" : bookTitle;
        case "Silindi":
            return language === "tr" ? "Silindi" : language === "en" ? "Deleted" : bookTitle;
        default:
            return "";
    }
};

export const getPermissionTutorialVideoUrl = (operatingSystem, browser) => {
    // Default değerleri belirle
    const defaultOperatingSystem = 'Windows';
    const defaultBrowser = 'chrome';

    // Video URL'lerini tanımla
    const videoUrls = {
        Windows: {
            chrome: 'https://www.youtube.com/watch?v=AqKKauBKOhs',
            edge: 'https://www.youtube.com/watch?v=kwga4EzYMo0',
            firefox: 'https://www.youtube.com/watch?v=3pf8MR2z_As',
        },
        macOS: {
            chrome: 'https://www.youtube.com/watch?v=NXdUMEkhIWE',
            edge: 'https://www.youtube.com/watch?v=jiWBsJJhj0Y',
            firefox: 'https://www.youtube.com/watch?v=rPUHjI-mQhg',
            safari: 'https://www.youtube.com/watch?v=hwEHVXt42CQ',
        },
        default: {
            chrome: 'https://www.youtube.com/watch?v=AqKKauBKOhs',
        },
    };

    // İşletim sistemini kontrol et
    const selectedOperatingSystem = videoUrls[operatingSystem] ? operatingSystem : defaultOperatingSystem;

    // Tarayıcıyı kontrol et
    const selectedBrowser = videoUrls[selectedOperatingSystem][browser]
        ? browser
        : defaultBrowser;

    // Video URL'ini döndür
    return videoUrls[selectedOperatingSystem][selectedBrowser];
}

export const getLanguageNameForService = (language) => {
    if(language === 'az'){
        return process.env.REACT_APP_LANG
    } else if (language === 'en') {
        return process.env.REACT_APP_LANG_EN
    } else {
        return process.env.REACT_APP_LANG
    }
}

export const getLanguageIdByLanguageKey = (language) => {
    switch(language){
        case 'tr':
            return 1;
        case 'en':
            return 4;
        default:
            return 1;
    }
}

export const getLanguageNameForCurrentLanguage = (languageKey, currentLanguage) => {
    if(languageKey === 'tr'){
        return currentLanguage === 'tr' ? 'Türkçe' : currentLanguage === 'en' ? 'Turkish' : '';
    }
    else if(languageKey === 'en'){
        return currentLanguage === 'tr' ? 'İngilizce' : currentLanguage === 'en' ? 'English' : '';
    }
}

export const getCardYears = () => {
    const thisYear = new Date().getFullYear();
    let yearArray = [];

    for (let year = thisYear; year <= (thisYear + 18); year++) {
        const tempYearObject = {
            id: year,
            name: year.toString()
        }
        yearArray.push(tempYearObject);
    }

    return yearArray;
}

export const getCardMonths = () => {
    let monthArray = [];

    for (let month = 1; month <= 12; month++) {
        const tempMonthObject = {
            id: month,
            name: month < 10 ? "0" + month : month.toString()
        }
        monthArray.push(tempMonthObject);
    }

    return monthArray;
}

export const getInstallments = (max, strings) => {
    let installmentArray = [];

    for (let ins = 1; ins <= max; ins++) {
        if (ins === 1) {
            installmentArray.push({ value: 0, option: strings.market.checkout.one_shot });
        } else {
            installmentArray.push({ value: ins, option: ins });
        }
    }

    return installmentArray;
}

export const convertToTurkishUpper = (text) => {
    const turkishChars = ['ı', 'i', 'ş', 'ğ', 'ü', 'ö', 'ç', 'İ', 'I', 'Ş', 'Ğ', 'Ü', 'Ö', 'Ç'];
    const englishChars = ['I', 'I', 'S', 'G', 'U', 'O', 'C', 'I', 'I', 'S', 'G', 'U', 'O', 'C'];

    let converted = text;

    for (let i = 0; i < turkishChars.length; i++) {
        const regex = new RegExp(turkishChars[i], 'g');
        converted = converted.replace(regex, englishChars[i]);
    }

    converted = converted.toUpperCase();
    return converted;
};

export const getAnswerTitleByIndex = (index) => {
    switch (index) {
        case 0:
            return "a)";
        case 1:
            return "b)";
        case 2:
            return "c)";
        case 3:
            return "d)";
        default:
            return "";
    }
}

export const getActionIconByActionStatus = (status) => {
    switch (status) {
        case 100:
            return { icon: NotStarted, className: "border border-[#909195]" };
        case 200:
            return { icon: Preparation, className: "bg-[#B96634]"};
        case 300:
            return { icon: Accept, className: "bg-[#CB8132]" };
        case 400:
            return { icon: Knowledgeable, className: "bg-[#938EBA]" };
        case 500:
            return { icon: Awsome, className: "bg-[#55418F]" };
        default:
            return "";
    }
}

export const getLevelCollectionById = (levelCollectionId) => {
    switch (levelCollectionId) {
        case 2:
            return "Starter";
        case 3:
            return "Beginner";
        case 4:
            return "Intermediate";
        case 5:
            return "Advanced";
        default:
            return "";
    }
}

export const getUseCaseById = (useCaseId) => {
    switch (useCaseId) {
        case 1:
            return "To use in business life";
        case 2:
            return "To rise in your career";
        case 3:
            return "To use in Business Meetings";
        case 4:
            return "Participating in an interview/job interview";
        case 5:
            return "Overseas business trips";
        case 6:
            return "Overseas vacation";
        case 7:
            return "Going abroad to work";
        case 8:
            return "Communication with close relatives or friends";
        case 9:
            return "Students who will use it at school";
        default:
            return "";
    }
}

export const getColorForScore = (value) => {
    if (value >= 80) return '#66bb6a'; // 80-100 arası
    if (value >= 60) return '#F16C00'; // 60-79 arası
    return '#f25961'; // 0-59 arası
};

// URL'deki query parametrelerini almak için kullanılan fonksiyon
export const getQueryParam = (name, location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(name);
};

export const getDefaultPhotoByGender = (gender, memberId = "") => {
    const officialMemberId = process.env.REACT_APP_OFFICIAL_MEMBER_ID;

    if (memberId === officialMemberId) {
        return DefaultProfileKo;
    }

    switch (Number(gender)) {
        case Gender.MALE:
            return Male;
        case Gender.FEMALE:
            return Female;
        case 2:
        case null:
        case undefined:
        default:
            return NoneGender;
    }
};

export const generateTimes = (startHour, endHour, intervalMinutes) => {
    const slots = [];
    let currentTime = new Date();
    currentTime.setHours(startHour, 0, 0, 0);

    const endTime = new Date();
    endTime.setHours(endHour, 0, 0, 0);

    while (currentTime <= endTime) {
        const hours = String(currentTime.getHours()).padStart(2, '0');
        const minutes = String(currentTime.getMinutes()).padStart(2, '0');
        slots.push(`${hours}:${minutes}`);

        currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
    }

    return slots;
}